.splash-view {
  align-items: center;  
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #f7f8fc;

  img {
    width: 420px;
  }
}