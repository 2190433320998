.country-flags {

    .content {
        display: flex;
        justify-content: center;
        align-items: center;

        &.top {
            flex-direction: column-reverse;
        }

        &.right {
            flex-direction: row;
            
            & > .label {
                margin-left: 8px;
            }
        }

        &.bottom {
            flex-direction: column;
        }

        &.left {
            flex-direction: row-reverse;
            
            & > .label {
                margin-right: 8px;
            }
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}