.container {

    img.logo {
        width: 142px;
    }

    .wellcome-subtitle {
        margin-top: 0px;
    }

    .signin {
        width: 120px;
    }
}